@tailwind base;
@tailwind components;


@layer components {
  .header_shadow {
    background: #FFF;
    box-shadow: 0px 5px 6.5px 0px rgba(0, 0, 0, 0.05);
  }

  .my-table th,
  .my-table td {
    padding: 0.3rem 0.6rem;
  }

  .menu_h {
    min-height: calc(100vh - 80px);
  }

  .landing_hero_bg {
    background: url(./assets/images/serenaverse_hero.webp) no-repeat center center;
    background-size: cover !important;
    border: 0;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    /* Rectangle 3 */

    box-sizing: border-box;
  }

  .landing_hero_bg_md {
    background: url(./assets/images/serenaverse_hero.webp) no-repeat top center;
    background-size: contain !important;
    border: 0;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    /* Rectangle 3 */

    box-sizing: border-box;
  }

  .news_hero_bg {
    background: url(./assets/images/news_hero.png) no-repeat center center;
    background-size: cover !important;
    border: 0;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    /* Rectangle 3 */

    box-sizing: border-box;
  }

  .career_hero_bg {
    background: url(./assets/images/career_hero.png) no-repeat center center;
    background-size: cover !important;
    border: 0;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    /* Rectangle 3 */

    box-sizing: border-box;
  }

  .experience_bg {
    background: linear-gradient(95.8deg, rgba(0, 0, 0, 0.6) -1.55%, rgba(0, 0, 0, 0.6) 115.58%);
    /* border-radius: 6px 0px 0px 0px; */
    box-shadow: inset 0px -5px 4px rgba(0, 0, 0, 0.1);
  }


  .wellness_bg {
    background: #66B929;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.16);

  }

  .custom_combo {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearence: none;
    padding: 2px 2px 2px 2px;
    background-image: url(./assets/images/select_trigger.svg) !important;
    background-position: right center;
    background-repeat: no-repeat;
  }

  .rotateY {
    transform: rotateY(180deg);
  }

  .start_nw {
    background: #0098DB;
    backdrop-filter: blur(10px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 6px 0px 0px 0px;
    /* Group 662 */

    box-shadow: inset 0px -5px 4px rgba(0, 0, 0, 0.1);
    color: #FFFFFF;
  }

  .news_start_bg {
    /* Group 662 */
    background: linear-gradient(180deg, #66B929 0%, #0098DB 126.71%);
    backdrop-filter: blur(10px);
    border-radius: 6px 0px 0px 0px;
    box-shadow: inset 0px -5px 4px rgba(0, 0, 0, 0.1);
  }

  .md-h-vw-port {
    min-height: calc(100vh - 390px) !important;
  }

  .xl-h-vw-port {
    min-height: calc(100vh - 240px) !important;
  }
  .md-not-found-h-vw-port {
    min-height: calc(100vh - 230px) !important;
  }

  .xl-not-found-h-vw-port {
    min-height: calc(100vh - 230px) !important;
  }

  .faq_desc {
    background: #FAFBFF;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
  }

}

.sr-only {
  display: none !important;
}

.left0 {
  left: 0 !important;
}


@tailwind utilities;